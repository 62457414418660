@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: 'Roboto', sans-serif;
}
